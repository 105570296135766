import * as React from "react"

import { navigate } from "gatsby"

export default function Index() {
  React.useEffect(() => {
    navigate("/formular")
  }, [])

  return null
}
